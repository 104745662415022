<template>
  <Html
    :lang="head.htmlAttrs.lang"
    :dir="head.htmlAttrs.dir"
  >
    <Head>
      <Title>{{ title }}</Title>
    </Head>
    <Body :class="theme">
      <UiComponentWrapper id="app">
        <UiComponentWrapper class="app__layout-body">
          <slot />
        </UiComponentWrapper>

        <!-- Modal > Demo - Step 1 -->
        <Transition
          @enter="onEnter"
          @leave="onLeave"
        >
          <UiComponentModal
            v-if="forms.demo.isVisible && !forms.demo.hasBeenSubmitted"
            key="modal-demo"
            class="app__modal demo"
            @modal:close="forms.demo.isVisible = false"
          >
            <template #title>
              {{ demoForm.firstStepTitle }}
            </template>
            <template #description>
              {{ demoForm.firstStepText }}
            </template>
            <template #content>
              <UiComponentWrapper class="content">
                <UiComponentWrapper class="left">
                  <UiComponentImage
                    alt="Background"
                    class="background"
                    :expected-size="530"
                    :height="2355"
                    orientation="portrait"
                    source="https://livestorm.imgix.net/1127/1646403148-blue-pattern.jpg"
                    :width="1590"
                  />
                  <UiComponentHeading
                    as="paragraph"
                    :level="2"
                  >
                    Need a Livestorm demo?
                  </UiComponentHeading>
                  <UiComponentHeading
                    as="paragraph"
                    :level="3"
                  >
                    Need a Livestorm demo?
                  </UiComponentHeading>
                  <UiComponentParagraph
                    class="description"
                    size="s"
                  >
                    Leave your information to schedule a demo with a product expert.
                    We will run you through the features and answer your
                    questions.
                  </UiComponentParagraph>
                  <UiComponentWrapper class="testimonial">
                    <UiComponentImage
                      alt="Logo"
                      class="logo"
                      :expected-size="18"
                      :extra-params="{ bri: 100 }"
                      :height="56"
                      orientation="portrait"
                      source="https://livestorm.imgix.net/1127/1618317822-klaviyo.png"
                      :width="244"
                    />
                    <UiComponentParagraph
                      class="quote"
                      size="s"
                    >
                      "We were looking for a simple webinar software that was not
                      over-engineered. If you do a side-by-side feature comparison,
                      Livestorm competes very well with the other webinar
                      players."
                    </UiComponentParagraph>
                    <UiComponentWrapper class="author">
                      <UiComponentImage
                        alt="Author"
                        class="picture"
                        :expected-size="32"
                        :height="400"
                        source="https://livestorm.imgix.net/1127/1505223008-clement.jpg"
                        :width="400"
                      />
                      <UiComponentParagraph class="name">
                        Michel Palaref
                      </UiComponentParagraph>
                      <UiComponentParagraph class="job">
                        Account Manager
                      </UiComponentParagraph>
                    </UiComponentWrapper>
                  </UiComponentWrapper>
                  <UiComponentWrapper class="logos">
                    <UiComponentWrapper
                      v-for="i in 2"
                      :key="i"
                      class="row"
                    >
                      <UiComponentWrapper
                        v-for="j in 4"
                        :key="`${i}-${j}`"
                        class="cell"
                      >
                        <UiComponentImage
                          alt="Logo"
                          class="logo"
                          :expected-size="80"
                          :extra-params="{ bri: 100 }"
                          :height="56"
                          source="https://livestorm.imgix.net/1127/1618317822-klaviyo.png"
                          :width="244"
                        />
                      </UiComponentWrapper>
                    </UiComponentWrapper>
                  </UiComponentWrapper>
                </UiComponentWrapper>
                <UiComponentWrapper class="right">
                  <UiComponentWrapper class="headline">
                    <UiComponentImage
                      alt="Flag"
                      class="flag fr"
                      :expected-size="16"
                      :height="16"
                      orientation="portrait"
                      source="https://livestorm.imgix.net/1127/1646403152-flag-of-france.svg"
                      :width="24"
                    />
                    <UiComponentImage
                      alt="Flag"
                      class="flag uk"
                      :expected-size="16"
                      :height="16"
                      orientation="portrait"
                      source="https://livestorm.imgix.net/1127/1646403153-flag-of-the-united-kingdom.svg"
                      :width="24"
                    />
                    <UiComponentImage
                      alt="Flag"
                      class="flag us"
                      :expected-size="16"
                      :height="16"
                      orientation="portrait"
                      source="https://livestorm.imgix.net/1127/1646403153-flag-of-the-united-states.svg"
                      :width="24"
                    />
                    <UiComponentParagraph size="s">
                      A Webinar Solution used by the best US companies for their
                      business
                    </UiComponentParagraph>
                  </UiComponentWrapper>
                  <UiComponentWrapper class="options">
                    <UiComponentWrapper
                      class="item"
                      :class="{ selected: currentFormIndex === 0 }"
                      @click="currentFormIndex = 0"
                    >
                      <UiComponentImage
                        :alt="data.componentForm.demoOptionTitle"
                        class="icon"
                        :expected-size="16"
                        :height="16"
                        source="https://livestorm.imgix.net/1127/1694504711-book-a-call.svg"
                        :width="16"
                      />
                      <UiComponentWrapper class="description">
                        <UiComponentParagraph>{{ data.componentForm.demoOptionTitle }}</UiComponentParagraph>
                        <UiComponentParagraph>{{ data.componentForm.demoOptionText }}</UiComponentParagraph>
                      </UiComponentWrapper>
                    </UiComponentWrapper>
                    <UiComponentWrapper
                      class="item"
                      :class="{ selected: currentFormIndex === 1 }"
                      @click="currentFormIndex = 1"
                    >
                      <UiComponentImage
                        :alt="data.componentForm.watchAVideoOptionTitle"
                        class="icon"
                        :expected-size="16"
                        :height="16"
                        source="https://livestorm.imgix.net/1127/1694504712-watch-a-video.svg"
                        :width="16"
                      />
                      <UiComponentWrapper class="description">
                        <UiComponentParagraph>{{ data.componentForm.watchAVideoOptionTitle }}</UiComponentParagraph>
                        <UiComponentParagraph>{{ data.componentForm.watchAVideoOptionText }}</UiComponentParagraph>
                      </UiComponentWrapper>
                    </UiComponentWrapper>
                  </UiComponentWrapper>
                  <OrganismHubspotNativeForm
                    v-if="currentFormIndex === 0"
                    :form-id="demoForm.formId"
                    unique-id="form-demo-modal"
                    @hubspot:form:submitted="onSubmitted(demoForm, $event)"
                  />
                  <OrganismHubspotNativeForm
                    v-else
                    :form-id="watchVideoForm.formId"
                    unique-id="form-watch-a-video-modal"
                    @hubspot:form:submitted="onSubmitted(watchVideoForm, $event)"
                  />
                </UiComponentWrapper>
              </UiComponentWrapper>
            </template>
          </UiComponentModal>
        </Transition>

        <!-- Modal > Demo - Step 2 -->
        <Transition
          @enter="onEnter"
          @leave="onLeave"
        >
          <UiComponentModal
            v-if="forms.demo.isVisible && forms.demo.hasBeenSubmitted && !isLoading"
            key="modal-success"
            class="app__modal success"
            @modal:close="forms.demo.isVisible = false"
          >
            <template #title>
              {{ demoForm.lastStepTitle }}
            </template>
            <template #content>
              <UiComponentWrapper class="content">
                <div
                  class="message"
                  v-html="$md.render(demoForm.lastStepText)"
                />
                <AtomButton
                  size="l"
                  :action="signupOrUpgradeCallToAction.action"
                  :to="signupOrUpgradeCallToAction.to"
                >
                  {{ signupOrUpgradeCallToAction.slot }}
                </AtomButton>
              </UiComponentWrapper>
            </template>
          </UiComponentModal>
        </Transition>
      </UiComponentWrapper>
    </Body>
  </Html>
</template>

<script setup>
import { gsap } from 'gsap'

// Composables
const { track } = useAnalyticsJS()
useChilipiper()
const { state } = useConditionalPreviewMode()
const { locale } = useI18n()
const { isLogged } = useIsLoggedIn()
const { $md } = useMarkdown()
const { meta } = useRoute()
const { public: publicRuntimeConfig } = useRuntimeConfig()
const isLoading = useState('loader', () => false)
const theme = useState('color-scheme', () => 'light')

// Async Data
const { data } = await useAsyncData(
  `layout-empty-${locale.value}`,
  async () => {
    return Promise.all([
      $fetch(`/api/content/layouts/default/${locale.value}`, { query: { preview: state.preview } }),
      $fetch('/api/content/layouts/rating-schema/en'),
    ])
  }, {
    transform: ([layoutData, ratingSchema]) => {
      const priceCurrency = locale.value === 'en' ? 'USD' : 'EUR'
      const proPlansCount = layoutData.componentPricing.proColumnPrice.length - 1
      return {
        ...layoutData,
        softwareApplicationSchema: {
          ...ratingSchema,
          applicationCategory: 'CommunicationApplication',
          offers: {
            '@context': 'https://schema.org/',
            '@type': 'AggregateOffer',
            priceCurrency,
            'lowPrice': layoutData.componentPricing.freeColumnPrice,
            'highPrice':
              priceCurrency === 'USD'
                ? layoutData.componentPricing.proColumnPrice[proPlansCount]
                  .priceEurYearly
                : layoutData.componentPricing.proColumnPrice[proPlansCount]
                  .priceEurYearly,
            'offeredBy': {
              '@context': 'https://schema.org/',
              '@type': 'Organization',
              'name': 'Livestorm',
              'url': 'https://livestorm.co/',
            },
            'priceSpecification': [
              {
                '@context': 'https://schema.org/',
                '@type': 'PriceSpecification',
                'price': layoutData.componentPricing.freeColumnPrice,
                priceCurrency,
                'name': layoutData.componentPricing.freeColumnTitle,
                'eligibleQuantity': {
                  '@context': 'https://schema.org/',
                  '@type': 'QuantitativeValue',
                  'unitText': layoutData.componentPricing.freeColumnSubTitle,
                },
              },
              ...layoutData.componentPricing.proColumnPrice.map(data => ({
                '@context': 'https://schema.org/',
                '@type': 'PriceSpecification',
                'price':
                  priceCurrency === 'USD'
                    ? data.priceUsdYearly
                    : data.priceEurYearly,
                priceCurrency,
                'name': layoutData.componentPricing.proColumnTitle,
                'eligibleQuantity': {
                  '@context': 'https://schema.org/',
                  '@type': 'QuantitativeValue',
                  'unitText': layoutData.componentPricing.proColumnSubTitle.replace(
                    '%{currentActiveContacts}',
                    data.mac,
                  ),
                },
              })),
            ],
          },
        },
      }
    },
  },
)

// States
const forms = useState('forms', () => {
  return data.value.allForms.reduce((accumulator, currentForm) => {
    return {
      ...accumulator,
      [currentForm.slug]: {
        hasBeenSubmitted: false,
        isVisible: false,
      },
    }
  }, {})
})

// Refs
const currentFormIndex = ref(0)

// Computed Properties
const demoForm = computed(() => {
  return data.value.allForms.find(({ slug }) => slug === 'demo')
})

const organisationSchema = computed(() => {
  return {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    'url': publicRuntimeConfig.baseUrl,
    'logo': data.value.global.logo.url,
  }
})

const signupOrUpgradeCallToAction = computed(() => {
  return isLogged.value
    ? {
        action: 'Upgrade clicked',
        to: `https://${publicRuntimeConfig.appBaseUrl}/#/settings?page=settings&tab=billing`,
        slot: data.value.global.ctaUpgradeNow,
      }
    : {
        action: 'Signup clicked',
        to: `https://${publicRuntimeConfig.appBaseUrl}/#/signup`,
        slot: data.value.global.ctaGetStartedForFree,
      }
})

const title = computed(() => {
  return meta.title || 'Livestorm'
})

const watchVideoForm = computed(() => {
  return data.value.allForms.find(({ slug }) => slug === 'watch-video')
})

// Watchers
watch(
  () => forms.value,
  () => {
    toggleScrollOnBody()
  },
  { deep: true },
)

// Methods
function onEnter($el, done) {
  gsap.from(
    $el.querySelector('.background'),
    {
      autoAlpha: 0,
      duration: 0.6,
      ease: 'expo.out',
    },
  )
  gsap.from(
    $el.querySelector('.box'),
    {
      autoAlpha: 0,
      delay: 0.15,
      duration: 0.6,
      ease: 'expo.out',
      onComplete: () => {
        done()
      },
      scale: 0.9,
      y: 80,
    },
  )
}

function onLeave($el, done) {
  gsap.to(
    $el.querySelector('.box'),
    {
      autoAlpha: 0,
      duration: 0.3,
      ease: 'expo.in',
      scale: 0.9,
      y: 80,
    },
  )
  gsap.to(
    $el.querySelector('.background'),
    {
      autoAlpha: 0,
      delay: 0.075,
      duration: 0.3,
      ease: 'expo.in',
      onComplete: () => {
        done()
      },
    },
  )
}

async function onSubmitted(currentForm, submittedValues) {
  // Trigger event to Google Tag Manager
  if (window.dataLayer && currentForm.gtmEvent) {
    const { v4: uuidv4 } = await import('uuid')
    dataLayer.push({ event: `${currentForm.gtmEvent}.${uuidv4()}` }) // Send event to GTM
  }

  // Trigger event to Segment
  const properties = currentForm.segmentWhitelistedProperties
    ? currentForm.segmentWhitelistedProperties
        .split(',')
        .map(value => value.trim())
        .reduce((accumulator, value) => {
          return {
            ...accumulator,
            [value]: submittedValues[value] ? submittedValues[value] : undefined,
          }
        }, {})
    : submittedValues

  await track(currentForm.segmentEvent || 'Form submitted', {
    ...properties,
    category: 'Form',
    form_id: currentForm.formId,
    label: currentForm.segmentLabel || currentForm.segmentEvent || 'Form submitted',
    locale: locale.value,
    title: window.document.title,
  })

  if (currentForm.redirectUrl) {
    window.location = `${publicRuntimeConfig.baseUrl}${currentForm.redirectUrl}`
  }
  else if (currentForm.slug === 'demo') {
    isLoading.value = true
    window.setTimeout(() => {
      forms.value.demo.hasBeenSubmitted = true
    }, 1000)
  }
  else {
    forms.value[currentForm.slug].hasBeenSubmitted = true
  }
}

function toggleScrollOnBody() {
  if (Object.values(forms.value).some(({ isVisible }) => isVisible)) {
    document.body.classList.add('no-scroll')
  }
  else {
    document.body.classList.remove('no-scroll')
  }
}

// SEO
const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true,
})

useSeoMeta({
  ogImage: data.value.openGraph.image.url,
})

// Structured Data
useLdJson(organisationSchema.value)
useLdJson(data.value.softwareApplicationSchema)
</script>

<style lang="postcss" scoped>
#app {
  @apply flex-col items-center;

  .app__layout {
    @apply flex-col items-center;

    .app__layout-body {
      @apply flex-col items-center;
    }
  }

  :deep(.app__modal) {
    &.demo  {
      .box {
        .body {
          .content {
            .left {
              @apply hidden;
            }

            .right {
              @apply flex-col;

              .headline {
                @apply hidden;
              }

              .options {
                @apply flex flex-col;

                @screen md {
                  @apply flex-row flex-wrap;
                }

                & + .native-form {
                  @apply mt-6;
                }

                .item {
                  @apply p-4 bg-white border border-grey-blue-300 cursor-pointer rounded;

                  @screen md {
                    width: calc((100% - 32px) / 2);
                  }

                  &.selected {
                    @apply bg-livestorm-blue-100 border-livestorm-blue-600 cursor-default;
                  }

                  &:last-child {
                    @apply mt-4;

                    @screen md {
                      @apply mt-0 ml-8;
                    }
                  }

                  .icon {
                    @apply flex-shrink-0 mt-1 rounded-none;
                  }

                  .description {
                    @apply flex-col ml-2;

                    p {
                      @apply font-medium text-winter-green-900 text-base leading-6;

                      & + p {
                        @apply mt-[2px] font-normal text-grey-blue-600 text-xs leading-[18px];
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &.experience {
      &.with-banner {
        .box {
          > img {
            @screen xl {
              top: 24px;
              right: 28px;
            }
          }

          .body {
            .content {
              .right {
                .headline {
                  @screen xl {
                    @apply flex;
                  }
                }
              }
            }
          }
        }
      }

      &.with-small-title {
        .box {
          .body {
            .content {
              .left {
                .h2 {
                  @apply hidden;
                }

                .h3 {
                  @apply flex;
                }
              }
            }
          }
        }
      }

      &.with-fr-flag {
        .box {
          .body {
            .content {
              .right {
                .headline {
                  .flag {
                    &.fr {
                      @apply flex;
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.with-uk-flag {
        .box {
          .body {
            .content {
              .right {
                .headline {
                  .flag {
                    &.uk {
                      @apply flex;
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.with-us-flag {
        .box {
          .body {
            .content {
              .right {
                .headline {
                  .flag {
                    &.us {
                      @apply flex;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .box {
        @screen xl {
          @apply p-0 overflow-hidden;
          max-width: 1060px !important;
        }

        .head {
          @screen xl {
            @apply hidden;
          }
        }

        .body {
          @screen xl {
            @apply mt-0;
          }

          .content {
            @screen xl {
              @apply flex-row;
            }

            .left,
            .right {
              @screen xl {
                @apply flex-col w-1/2;
              }
            }

            .left {
              @screen xl {
                @apply relative flex min-h-[700px] bg-livestorm-blue-700 text-white p-10;
              }

              .background {
                @apply absolute inset-0 rounded-none z-0;
              }

              .h2,
              .h3 {
                @apply text-white z-10;
              }

              .h3 {
                @apply hidden;
              }

              .description {
                @apply font-medium text-white whitespace-normal mt-2 mb-18 leading-6 z-10;
              }

              .testimonial {
                @apply flex-col w-full mt-auto p-6 border-2 border-livestorm-blue-200 rounded-4xl z-10;
                background: rgba(255, 255, 255, 0.1);

                .logo {
                  @apply rounded-none;
                }

                .quote {
                  @apply text-grey-blue-100 whitespace-normal mt-4 leading-6;
                }

                .author {
                  @apply items-center w-full mt-4;

                  .picture {
                    @apply flex-shrink-0 border border-grey-blue-100 rounded-full;
                  }

                  .info {
                    @apply flex-col ml-3;

                    .name {
                      @apply font-medium text-base text-white leading-6;
                    }

                    .job {
                      @apply text-base text-grey-blue-100 leading-6;
                    }
                  }
                }
              }

              .logos {
                @apply flex-col w-full mt-12;

                .row {
                  @apply w-full justify-between mt-4;

                  &:first-child {
                    @apply mt-0;
                  }

                  .cell {
                    @apply justify-start items-center w-auto;

                    .logo {
                      @apply rounded-none;
                    }
                  }
                }
              }
            }

            .right {
              .headline {
                @screen xl {
                  @apply hidden justify-center w-full pl-10 pr-12 py-5 bg-grey-blue-200;
                }

                .flag {
                  @apply hidden flex-shrink-0 rounded-none mt-1 mr-2;
                }

                p {
                  @apply ml-2 font-medium text-green-900 whitespace-normal leading-6;
                }
              }

              .native-form,
              iframe {
                @screen xl {
                  @apply my-auto h-full p-10;
                }
              }
            }
          }
        }
      }
    }

    &.success {
      .box {
        .body {
          .content {
            @apply flex-col;

            .message {
              @apply font-normal text-winter-green-900 text-origin leading-7;

              p {
                & + p {
                  @apply mt-6;
                }

                a {
                  @apply text-livestorm-blue-700;
                }
              }
            }

            .button {
              @apply mt-6;
            }
          }

        }
      }
    }
  }
}
</style>
